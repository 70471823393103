<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-13 16:57:04
 * @LastEditors: heqi
 * @LastEditTime: 2022-09-09 10:11:32
-->
<template>
  <div class="okr-page-wrapper">
    <router-view name='okrNav'></router-view>
    <div class="okr-content-wrapper">
      <router-view name='okrContent'></router-view>
    </div>
  </div>
</template>

<script >
import { reactive, toRefs } from '@vue/reactivity'

export default {
  setup () {
    const state = reactive({})

    return { ...toRefs(state) }
  }
}
</script>

<style lang="less" scoped>
.okr-page-wrapper {
  display: flex;
}

.okr-content-wrapper {
  position: relative;
  flex: 1;
}
.okr-child {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-left: 18px !important;
  margin-bottom: 8px !important;
}
</style>
